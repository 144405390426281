<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-30 16:24:18
-->
<template>
  <el-dialog
    :title="(type == 'create' ? '新增' : '編輯') + title"
    :visible.sync="showDialog"
    width="60%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="180px" :model="form">
        <el-form-item label="主題級別">
          <el-select
            v-model="form.level"
            @change="changeLevel"
            placeholder="請選擇主題級別"
          >
            <el-option
              v-for="(item, index) in levels"
              :key="index"
              :label="item"
              :value="index + 1"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所屬主題" v-if="form.level > 1">
          <el-select
            v-model="form.pid"
            filterable
            placeholder="請選擇所屬主題"
            v-loading="loading2"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所屬主題">
            <el-select v-model="form.level" placeholder="請選擇所屬主題">
              <el-option
                v-for="(item, index) in levels"
                :key="index"
                :label="item"
                :value="index + 1"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="主題名稱">
          <el-input
            v-model="form.title"
            placeholder="请输入主題名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="主題名稱 （English）">
          <el-input
            v-model="form.title_en"
            placeholder="请输入主題名稱（English）"
          ></el-input>
        </el-form-item>
        <el-form-item label="顯示權重">
          <el-input-number
            v-model="form.sort"
            label="顯示權重"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="saving"
        >確 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "create",
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    level: {
      type: [String, Number],
      default: 1,
    },
  },
  data() {
    return {
      title: "文章主題",
      showDialog: false,
      loading2: false,
      loading: false,
      saving: false,
      options: [],
      levels: ["一级主題", "二级主題"],
      form: {
        level: 1,
        title: "",
        title_en: "",
        pid: "",
        sort: "1",
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        this.form = {
          level: 1,
          title: "",
          title_en: "",
          pid: "",
          sort: "1",
        }
        this.form.level = this.level * 1
        if (this.level > 1) {
          this.changeLevel(this.level)
        }
        if (this.type == "edit") {
          this.getData(this.item.id)
        }
      }
    },
  },
  methods: {
    changeLevel(v) {
      if (v > 1) {
        this.getCatByLevel()
      }
      // 建议设置为空
    },
    async getCatByLevel() {
      this.loading2 = true
      let res = await this.api.help.getLevels(this.form.level)
      this.loading2 = false
      if (res && res.code == 0) {
        this.options = res.data
      }
    },
    async getData(id) {
      this.loading = true
      let res = await this.api.help.getOneCat(id)
      this.loading = false
      if (res && res.code == 0) {
        res.data = res.data ? res.data : {}
        for (let p in this.form) {
          this.form[p] = res.data[p]
        }
        if (res.data.allpid) {
          let arr = res.data.allpid.split(",")
          this.form.pid = arr[arr.length - 1] * 1
        }
        this.form.id = this.item.id
      }
    },
    confirmImg(arr) {
      let file = arr.files[0]
      if (file.size > 2 * 1024 * 1024) {
        this.$message.warning("圖片大小不能超過 2MB!")
        return
      }
      let formData = new FormData()
      formData.append("file", file)
      this.api.system.uploadFile(formData).then((res) => {
        console.log(res)
        if (res && res.code == 0) {
          this.form.background = res.data
        }
      })
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      if (this.form.level == 1) {
        // this.form.background = ""
        this.form.pid = ""
      }
      if (this.form.level < 3) {
        // 建议重置
        // this.form.advice_en = ""
        // this.form.advice = ""
      }
      this.saving = true
      this.$emit("confirm", this.form)
    },
  },
}
</script>
<style scoped></style>
